/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $mobileMenu = $('.mobile-menu'),
            scrollClass = 'scrolled',
            activateAtY = 20;

        function deactivateHeader() {
            if (!$mobileMenu.hasClass(scrollClass)) {
                $mobileMenu.addClass(scrollClass);
            }
        }

        function activateHeader() {
            if ($mobileMenu.hasClass(scrollClass)) {
                $mobileMenu.removeClass(scrollClass);
            }
        }

        $(window).scroll(function() {
          if($(window).scrollTop() > activateAtY) {
              deactivateHeader();
          } else {
              activateHeader();
          }
        });

        $('.menu-link').on('click', function(e){
          e.preventDefault();
          $('.mobile-menu-links').toggleClass('visible');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Business Service
    'business_services': {
      init: function() {
        // JavaScript to be fired on the home page
        var $business = $('#business-type').text();
        $('#contact-message').val("I'm interested in services for "+$business);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Business Service
    'single_service': {
      init: function() {
        // JavaScript to be fired on the home page
        var $service = $('.page-title').text();
        $('#contact-subject').val("I'm interested in more informationabout  "+ $service);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Outsourced
    'outsourced': {
      init: function() {
        // JavaScript to be fired on the Outsourced page



        var resizeTimer; // Set resizeTimer to empty so it resets on page load

        function resize_reasons(){
          $('.reason').removeClass('triggered');
          $.each( $('.reason_details'), function(key, value){
            var $height = $(this).height() * 1.7;
            console.log($height);
            $(this).parent().css('min-height', $height);
          });
        }

        // On resize, run the function and reset the timeout
        // 250 is the delay in milliseconds. Change as you see fit.
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(resize_reasons, 250);
        });

        resize_reasons();

        $( ".reason_link" ).click(function(e) {
          e.preventDefault();
          //$(this).parent().find('.reason_details').toggleClass('triggered')
          $(this).parent().toggleClass('triggered');
          $(this).parent().find('.fa').toggleClass('fa-rotate-180');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
